export default [
  {
    text: "Resource Selection",
    path: "/resource-selection",
    icon: "columnchooser",
    translationKey: "side_nav.resource_selection",
  },
  {
    text: "Dashboard",
    path: "/dashboard",
    icon: "globe",
    translationKey: "side_nav.dashboard",
  },
  // {
  //   text: "Activity",
  //   path: "/activity-detail",
  //   icon: "runner",
  // },
  {
    text: "Activity",
    path: "/activity-plan",
    icon: "bulletlist",
    translationKey: "side_nav.activity_plan",
  },
  {
    text: "Other Activity",
    icon: "runner",
    translationKey: "side_nav.other_activity.name",
    items: [
      // {
      //   text: "Perform Activity",
      //   path: "/perform-activity",
      //   icon: "runner",
      //   translationKey: "side_nav.other_activity.child.perform_activity",
      // },
      {
        text: "Refuel or Toll",
        path: "/refuel-or-toll",
        icon: "car",
        translationKey: "side_nav.other_activity.child.refuel_or_toll",
      },
    ],
  },
  {
    text: "History Progress",
    path: "/pod",
    icon: "box",
    translationKey: "side_nav.history_progress",
  },
  {
    text: "Communications",
    icon: "tel",
    translationKey: "side_nav.communications.name",
    items: [
      {
        text: "Chat",
        path: "/chat",
        icon: "message",
        translationKey: "side_nav.communications.child.chat",
      },
      {
        text: "Send Message",
        path: "/send-message",
        icon: "message",
        translationKey: "side_nav.communications.child.send_message",
      },
    ],
  },
  {
    text: "Create Task",
    path: "/create-task",
    icon: "rename",
    translationKey: "side_nav.create_task",
  },
  {
    text: "Settings",
    icon: "preferences",
    translationKey: "side_nav.settings.name",
    items: [
      {
        text: "Profile",
        path: "/profile",
        icon: "user",
        translationKey: "side_nav.settings.child.profile",
      },
      {
        text: "About",
        path: "/about",
        icon: "info",
        translationKey: "side_nav.settings.child.about",
      },
    ],
  },
];
