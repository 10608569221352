<template>
  <div id="content-container" style="flex: 1" class="activity-plan">
    <h2 class="content-block">{{ $t("activity.title") }}</h2>
    <div class="content-block">
      <div class="dx-card responsive-paddings">
        <DxButton
          icon="arrowleft"
          width="72"
          @click="handlePrevBtnClick"
          :disabled="!prevButtonEnabled"
        />
        <DxButton
          icon="arrowright"
          width="72"
          @click="handleNextBtnClick"
          :disabled="!nextButtonEnabled"
        />
        <br /><br />
        <DxList
          ref="plansList"
          class="activity-plan-list"
          :data-source="plansDataSource"
          :pullRefreshEnabled="false"
          height="600px"
          :selectionMode="listSelectionMode"
          :focusStateEnabled="false"
          :hoverStateEnabled="true"
          :activeStateEnabled="true"
          :bounceEnabled="true"
          :scrollByThumb="true"
          :scrollByContent="true"
          :useNativeScrolling="true"
          @itemClick="handleItemClick"
          @contentReady="handleListContentReady"
          @selectionChanged="handleSelectionChanged"
          :selectByClick="true"
        >
          <template #item="{ data: item }">
            <div :class="planItemContainerClassName(item)">
              <span
                v-if="
                  item.Activity != activityType.PICK_UP_TASK &&
                  item.Activity != activityType.DROP_OFF_TASK
                "
                style="
                  font-size: 1.5em;
                  font-weight: 700;
                  margin-left: 0.2em;
                  display: flex;
                  align-items: center;
                "
              >
                {{ item.Seq + 1 }} - {{ item.TaskID }}
              </span>

              <span
                v-if="
                  item.Activity == activityType.PICK_UP_TASK ||
                  item.Activity == activityType.DROP_OFF_TASK
                "
                style="
                  font-size: 1.5em;
                  font-weight: 700;
                  margin-left: 0.2em;
                  display: flex;
                  align-items: center;
                "
              >
                {{ getPickUpDropOffTaskSeq(item) }}
              </span>

              <div style="height: 0.1em"></div>
              <div
                style="
                  font-size: 1.3em;
                  font-weight: 700;
                  display: flex;
                  align-items: center;
                "
              >
                <i
                  :class="activityTypeIconClass(item.Activity)"
                  style="font-size: 1.2em; margin-right: 12px"
                />
                {{ item.Activity }}
              </div>
              <div style="height: 1.5em"></div>
              <div
                style="
                  display: flex;
                  align-items: center;
                  font-size: 1.5em;
                  font-weight: 500;
                "
              >
                <i
                  class="dx-icon-home"
                  style="font-size: 1.1em; margin-right: 12px"
                />{{ item.SiteID }}
              </div>
              <div
                style="
                  display: flex;
                  align-items: center;
                  font-size: 1.5em;
                  font-weight: 500;
                "
              >
                <i
                  class="dx-icon-home"
                  style="
                    font-size: 1.1em;
                    margin-right: 12px;
                    color: transparent;
                  "
                /><span
                  style="
                    font-size: 0.6em;
                    font-weight: 500;
                    word-wrap: break-word;
                    white-space: pre-wrap;
                  "
                  >{{ item.Sites.Description }}</span
                >
              </div>
              <div style="height: 0.3em"></div>
              <div
                style="
                  display: flex;
                  align-items: center;
                  font-size: 1.5em;
                  font-weight: 500;
                "
              >
                <i
                  class="dx-icon-clock"
                  style="font-size: 1.1em; margin-right: 0.6em"
                />
                <div style="font-size: 0.7em">
                  <div>{{ getFormatedDateTime(item.ExpArriveTime) }}</div>
                  <div style="color: #dc143c">
                    {{ getFormatedDateTime(item.ExpDepartTime) }}
                  </div>
                </div>
              </div>
            </div>
          </template>
        </DxList>
      </div>
    </div>
    <ActivityActions
      :visible="isActivityActionsVisible"
      :hidden="handleActivityActionsHide"
      :selected-plan="selectedPlan"
    />
    <Receivers
      :visible="isReceiversVisible"
      :hidden="handleReceiversHide"
      :siteId="selectedPlanSiteId"
      :tasks="receiversTasks"
      :receiversParentSeq="receiversParentSeq"
      :truck-mode="selectedPlanTruckMode"
      v-if="isReceiversVisible"
    />
    <DxLoadPanel
      :visible="isLoading"
      :position="{ of: '#root' }"
      shading-color="rgba(0,0,0,0.4)"
      :close-on-outside-click="false"
    />
  </div>
</template>
<script>
import moment from "moment";
import util from "../../commons/utility";
import service from "../../commons/service";
import DxLoadPanel from "devextreme-vue/load-panel";
import DxList from "devextreme-vue/list";
import DxButton from "devextreme-vue/button";
import { activityType } from "../../commons/constants";
import { truckMode } from "../../commons/constants";
import DataSource from "devextreme/data/data_source";
import ActivityActions from "./activity-actions.vue";
import Receivers from "./receivers.vue";

export default {
  async mounted() {
    const hasSelectedResourceAndAllocation =
      await util.hasSelectedResourceAndAllocation();

    if (!hasSelectedResourceAndAllocation) {
      this.isSelectedReourceAndAllocation = false;
      this.$root.showError("Please select resource first");
    } else {
      this.isSelectedReourceAndAllocation = true;
    }
  },
  data() {
    return {
      isLoading: false, // not used now
      isActivityActionsVisible: false,
      isReceiversVisible: false,
      selectedPlan: null,
      listSelectionMode: "none",
      plansDataSource: new DataSource({
        key: "Seq",
        onChanged: this.handlePlansDataChange,
        paginate: false,
        load: () => {
          const allocationId = util.getSelectedAllocationId();
          const promise = service.getTasksByAllocationId(allocationId, true);
          // transform current acivity plan list to have single item in list for tasks that has same siteid and task type
          promise.then((response) => {
            const summarizedPlans = [];
            const plansFromRemote = [...(response.data ?? [])];

            const dropOffAndPickUpTasks = plansFromRemote.reduce(
              (groups, plan) => {
                if (
                  plan.Activity == activityType.PICK_UP_TASK ||
                  plan.Activity == activityType.DROP_OFF_TASK
                ) {
                  const siteIDAndActivityType = `${plan.SiteID}|${plan.Activity}`;
                  groups[siteIDAndActivityType] =
                    groups[siteIDAndActivityType] || [];
                  groups[siteIDAndActivityType].push(plan);
                }

                return groups;
              },
              {}
            );

            for (var currIdx = 0; currIdx < plansFromRemote.length; currIdx++) {
              const currPlan = { ...plansFromRemote[currIdx] };

              if (
                currPlan.Activity == activityType.DROP_OFF_TASK ||
                currPlan.Activity == activityType.PICK_UP_TASK
              ) {
                // check prev plan if it is same type and same site
                const prevIdx = currIdx - 1;
                if (prevIdx >= 0) {
                  // there is a prev position
                  const prevPlan = plansFromRemote[prevIdx];
                  if (
                    `${currPlan.SiteID}|${currPlan.Activity}` !=
                    `${prevPlan.SiteID}|${prevPlan.Activity}`
                  ) {
                    // not been added before
                    currPlan["Children"] =
                      dropOffAndPickUpTasks[
                        `${currPlan.SiteID}|${currPlan.Activity}`
                      ] ?? [];
                    currPlan["LastChildSeq"] =
                      currPlan.Children[currPlan.Children.length - 1].Seq;
                    summarizedPlans.push(currPlan);
                  }
                } else {
                  summarizedPlans.push(currPlan);
                }
              } else {
                summarizedPlans.push(currPlan);
              }
            }

            response.data = summarizedPlans;
          });

          return promise;
        },
      }),
    };
  },
  computed: {
    activityType() {
      return activityType;
    },
    plans() {
      return this.plansDataSource.items();
    },
    prevButtonEnabled() {
      const selectedPlan = this.selectedPlan;
      return (
        selectedPlan != null && selectedPlan.Seq != null && selectedPlan.Seq > 0
      );
    },
    nextButtonEnabled() {
      if (
        this.selectedPlan == null ||
        this.plans == null ||
        this.plans.length == 0
      )
        return false;

      const selectedPlan = this.selectedPlan;
      const lastItem = this.plans[this.plans.length - 1];
      return lastItem.Seq != selectedPlan.Seq;
    },
    selectedPlanSiteId() {
      if (this.selectedPlan == null || this.selectedPlan.SiteID == null)
        return null;

      return this.selectedPlan.SiteID;
    },
    selectedPlanTruckMode() {
      if (this.selectedPlan == null) return null;

      if (this.selectedPlan.Activity == activityType.PICK_UP_TASK)
        return truckMode.LOAD;
      if (this.selectedPlan.Activity == activityType.DROP_OFF_TASK)
        return truckMode.UNLOAD;

      return null;
    },
    receiversTasks() {
      if (this.selectedPlan == null || this.selectedPlan.Children == null)
        return [];

      return this.selectedPlan.Children;
    },
    receiversParentSeq() {
      if (this.selectedPlan == null) return null;

      return String(this.selectedPlan.Seq);
    },
    isAllTasksCompleted() {
      const plans = this.plans;
      console.log("plans", plans);
      return true;
    },
  },
  methods: {
    getOnProcessIdx() {
      const plans = this.plans;
      const lastPlan = plans[plans.length - 1];
      if (!this.plans || !lastPlan) return;

      const completedTasks = util.getCompletedTasks();
      const skippedTasks = util.getSkippedTasks();

      if (
        lastPlan.Seq == completedTasks.length - 1 ||
        lastPlan.Seq == skippedTasks.length - 1
      ) {
        return -1;
      }

      for (let i = 0; i < plans.length; i++) {
        const plan = plans[i];
        const children = plan.Children;

        if (children != null) {
          let isAllComplete = true;
          const parentSeq = plan.Seq;

          for (const child of children) {
            if (
              !completedTasks.includes(child.Seq) &&
              !skippedTasks.includes(child.Seq)
            ) {
              isAllComplete = false;
            }
          }

          if (!isAllComplete) {
            return i;
          }
        } else {
          if (!completedTasks.includes(plan.Seq)) {
            return i;
          }
        }
      }

      return -1;
    },
    planItemContainerClassName(item) {
      const plans = this.plans;
      const itemIdx = plans.findIndex((plan) => plan.Seq === item.Seq);
      const completedTasks = util.getCompletedTasks();
      const skippedTasks = util.getSkippedTasks();

      let className = "plan-item-container";
      const seq = item.Seq ?? 0;
      const onProcessIdx = this.getOnProcessIdx();

      if (itemIdx === onProcessIdx) {
        className = className + "-on-going";
      } else if (completedTasks.includes(seq) || skippedTasks.includes(seq)) {
        className = className + "-completed";
      } else {
        className = className + "-open";
      }

      return className;
    },
    handlePlansDataChange() {
      const plans = this.plans;
      const localActivityPlan = util.getLocalActivityPlan();

      let currentSeq = -1;

      if (localActivityPlan != null) {
        if (localActivityPlan.LastChildSeq != null) {
          currentSeq = localActivityPlan.LastChildSeq;
        } else {
          currentSeq = localActivityPlan.Seq;
        }
      }
    },
    handleListContentReady() {
      const plans = this.plans;
      if (plans.length == 0) return;

      const selectedTaskSeq = this.$route.query["selectedTaskSeq"];
      const onProgressIdx = this.getOnProcessIdx();
      const itemPos = plans.findIndex(
        (element) => element.Seq == parseInt(selectedTaskSeq)
      );

      this.listSelectionMode = "single";
      if (selectedTaskSeq != null && itemPos > -1) {
        this.selectedPlan = plans[itemPos];
        this.$refs.plansList.instance.selectItem(selectedTaskSeq);
        this.$refs.plansList.instance.scrollToItem(selectedTaskSeq);
        this.selectItem(plans[itemPos]);
        return;
      }

      if (onProgressIdx != -1) {
        this.selectedPlan = plans[onProgressIdx];
        this.$refs.plansList.instance.selectItem(onProgressIdx);
        this.$refs.plansList.instance.scrollToItem(onProgressIdx);
      } else {
        this.listSelectionMode = "none";
      }
    },
    handlePrevBtnClick() {
      const selectedPlan = this.selectedPlan;
      if (selectedPlan == null) return;

      const currSeq = selectedPlan.Seq;
      if (currSeq == null || currSeq <= 0) return;

      const currPlans = this.plans;

      for (let i = currPlans.length - 1; i >= 0; i--) {
        const plan = currPlans[i];
        const seq = plan.Seq;

        if (seq < currSeq) {
          this.$refs.plansList.instance.selectItem(i);
          this.selectedPlan = this.plans[i];
          this.$refs.plansList.instance.scrollToItem(i);
          return;
        }
      }
    },
    handleNextBtnClick() {
      const plan = this.selectedPlan;
      if (plan == null || plan.Seq == null || plan.Seq < 0) return;

      const currSeq = plan.Seq;
      const plans = this.plans;

      for (let i = 0; i < plans.length; i++) {
        const plan = plans[i];
        const seq = plan.Seq;

        if (seq > currSeq) {
          this.$refs.plansList.instance.selectItem(i);
          this.selectedPlan = plan;
          this.$refs.plansList.instance.scrollToItem(i);
          return;
        }
      }
    },
    handleItemClick(e) {
      const item = e.itemData;
      this.selectItem(item);
    },
    selectItem(item) {
      this.selectedPlan = item;
      if (
        item.Activity == activityType.PICK_UP_TASK ||
        item.Activity == activityType.DROP_OFF_TASK
      ) {
        this.isReceiversVisible = true;
      } else {
        this.isActivityActionsVisible = true;
      }
    },
    handleSelectionChanged(e) {
      const dxListElement = e.element;

      const items = dxListElement.querySelectorAll(".dx-list-item");
      if (items == null || items.length == 0) return;

      items.forEach((item, index) => {
        const isSelected = item.classList.contains("dx-list-item-selected");

        const templateItem = item.firstChild;

        if (templateItem != null) {
          if (isSelected) {
            templateItem.classList.remove("plan-item-container-on-going");
            templateItem.classList.remove("plan-item-container-completed");
            templateItem.classList.remove("plan-item-container-open");
          } else {
            templateItem.classList.add(
              this.planItemContainerClassName(this.plans[index])
            );
          }
        }
      });
    },
    handleActivityActionsHide() {
      this.isActivityActionsVisible = false;
    },
    handleReceiversHide() {
      this.isReceiversVisible = false;
    },
    activityTypeIconClass(actType) {
      let activityIcon = "dx-icon-runner";

      switch (actType) {
        case activityType.START:
          activityIcon = "dx-icon-video";
          break;
        case activityType.PICK_UP_TASK:
          activityIcon = "dx-icon-upload";
          break;
        case activityType.DROP_OFF_TASK:
          activityIcon = "dx-icon-download";
          break;
        case activityType.TRAVEL_TO:
          activityIcon = "dx-icon-chevrondoubleright";
          break;
        case activityType.FINISH:
          activityIcon = "dx-icon-selectall";
          break;
      }

      return activityIcon;
    },
    getFormatedDateTime(dateTimeString, defaultValue = "") {
      if (dateTimeString == null) return defaultValue;
      const dateTime = moment.utc(dateTimeString);
      if (!dateTime.isValid()) return defaultValue;

      return dateTime.format("DD/MM/yyyy HH:mm:ss");
    },
    getPickUpDropOffTaskSeq(item) {
      if (item.Children == null || item.Children.length == 0) return "";

      if (item.Children.length == 1) return `${item.Children[0].Seq + 1}`;

      return `${item.Children[0].Seq + 1} - ${
        item.Children[item.Children.length - 1].Seq + 1
      }`;
    },
  },
  components: {
    DxLoadPanel,
    DxList,
    DxButton,
    ActivityActions,
    Receivers,
  },
};
</script>
<style lang="scss">
.activity-plan-list > .dx-scrollable-wrapper > .dx-scrollable-container > div {
  .dx-list-item,
  .dx-list-item-selected {
    margin-bottom: 1em !important;
    margin-top: 1em !important;
    border-radius: 1.5em !important;
  }

  .dx-list-item-selected {
    border-width: 3px;
    border: 3px solid #d3d3d3;
    border-top: 3px solid #d3d3d3;
    border-bottom: 3px solid #d3d3d3;
    color: #fffaeb !important;
    background-color: #4eaa85 !important;
  }
}

.activity-plan-list
  > .dx-scrollable-wrapper
  > .dx-scrollable-container
  > div
  > .dx-scrollview-content {
  .plan-item-container-on-going {
    background-color: #347235;
    color: #fffaeb;
  }

  .plan-item-container-open {
    background-color: #0288d1;
    color: #fffaeb;
  }

  .plan-item-container-completed {
    background-color: #f09d4e;
    color: #fffaeb;
  }

  .plan-item-container-open {
    background-color: #0288d1;
    color: #fffaeb;
  }
}
</style>
