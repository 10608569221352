<template>
  <div id="profile-content">
    <h2 class="content-block">{{ $t("profile.title") }}</h2>
    <div class="content-block dx-card responsive-paddings">
      <div class="form-avatar">
        <img src="@/assets/images/profile-user.svg" />
      </div>
      <span class="username-profile">{{ userName }}</span>
    </div>

    <div class="content-block dx-card responsive-paddings">
      <DxForm
        id="form"
        label-location="top"
        :form-data="formData"
        :items="translateFormItem(formItems)"
        :colCountByScreen="colCountByScreen"
        :read-only="true"
      />
      <LanguageSelect style="margin-top: 1em" />
    </div>

    <DxLoadPanel
      :position="panelPosition"
      :visible="isLoading"
      :show-indicator="true"
      :show-pane="true"
      :shading="false"
    />
  </div>
</template>

<script>
import i18n from "./../i18n";
import DxForm from "devextreme-vue/form";
import api from "../commons/api";
import DxLoadPanel from "devextreme-vue/load-panel";
import util from "../commons/utility";
import LanguageSelect from "../components/language-select";
import countryConfig from "../assets/data/country-config";

export default {
  mounted() {
    this.getProfile();
  },
  data() {
    return {
      isLoading: false,
      panelPosition: { of: "#profile-content" },
      countries: countryConfig.COUNTRIES,
      formItems: [
        {
          dataField: "name",
          label: {
            translationKey: "profile.lbl_name",
          },
        },
        {
          dataField: "tenantName",
          label: {
            translationKey: "profile.lbl_tenant_name",
          },
        },
        {
          dataField: "email",
          label: {
            translationKey: "profile.lbl_email",
          },
        },
        {
          dataField: "emailConfirmed",
          label: {
            translationKey: "profile.lbl_is_email_confirmed",
          },
        },
        {
          dataField: "phoneNumber",
          label: {
            translationKey: "profile.lbl_phone_number",
          },
        },
        {
          dataField: "accessFailedCount",
          label: {
            translationKey: "profile.lbl_access_failed_count",
          },
        },
        {
          dataField: "isConfirmed",
          label: {
            translationKey: "profile.lbl_is_confirmed",
          },
        },
      ],
      userName: "-",
      formData: {
        name: "-",
        tenantName: "-",
        email: "-",
        emailConfirmed: false,
        phoneNumber: "-",
        accessFailedCount: "-",
        isConfirmed: false,
      },
      colCountByScreen: {
        xs: 2,
        sm: 2,
        md: 2,
        lg: 4,
      },
    };
  },
  methods: {
    getProfile() {
      this.setLoading(true);

      this.$http
        .get(api.GET_USER_PROFILE_URL)
        .then((res) => {
          this.setLoading(false);

          let data = res.data;
          if (data != null) {
            this.userName = data.UserName;
            this.formData.name = data.UserName;
            this.formData.tenantName = util.getSelectedTenant().Name;
            this.formData.email = data.Email;
            this.formData.emailConfirmed = data.EmailConfirmed;
            this.formData.phoneNumber = data.PhoneNumber;
            this.formData.accessFailedCount = data.AccessFailedCount;
            this.formData.isConfirmed = data.IsConfirmed;
          }
        })
        .catch((err) => {
          console.error(err);
          this.setLoading(false);
        });
    },
    setLoading(isLoading) {
      this.isLoading = isLoading;
    },
    translateFormItem(formItems) {
      return formItems.map((item) => {
        item.label.text = i18n.t(item.label.translationKey);
        return { ...item };
      });
    },
  },
  components: {
    DxForm,
    DxLoadPanel,
    LanguageSelect,
  },
};
</script>

<style lang="scss">
.form-avatar {
  float: left;
  height: 60px;
  width: 60px;
  margin-right: 20px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #fff;
  overflow: hidden;

  img {
    height: 60px;
    display: block;
    margin: 0 auto;
  }
}

.username-profile {
  font-size: 20px;
}
</style>
