<template>
  <div id="content-container">
    <VuePullRefresh
      :on-refresh="onRefresh"
      :config="{
        startLabel: $t('common.lbl_loading'),
        errorLabel: $t('common.lbl_loading'),
        readyLabel: $t('common.lbl_loading'),
        loadingLabel: $t('common.lbl_loading'),
        pullDownHeight: 50,
      }"
    >
      <SelectedDataAccordion
        v-show="false"
        @onCurrentLocationChanged="onCurrentLocationChanged"
      />
      <h2 class="content-block">
        {{ $t("resource_selection.title") }}
      </h2>
      <div class="content-block dx-card">
        <div class="dx-fieldset">
          <div class="dx-fieldset-header">
            {{ $t("resource_selection.lbl_job") }}
          </div>
          <div class="dx-field">
            <DxLookup
              :data-source="allocationDataSource"
              :searchTimeout="100"
              display-expr="AllocationID"
              value-expr="AllocationID"
              :placeholder="allocationPlaceHolder"
              item-template="job-item"
              :userPopOver="false"
              valueChangeEvent="change"
              :onValueChanged="onAllocationChanged"
              :value="selectedAllocationId"
            >
              <DxDropDownOptions
                :close-on-outside-click="true"
                :show-title="false"
              />
              <template #job-item="{ data }">
                <JobItem :allocation="data" />
              </template>
            </DxLookup>
          </div>
        </div>
      </div>
      <div class="content-block dx-card responsive-paddings">
        <dx-form :col-count-by-screen="colCountByScreen">
          <dx-item :col-span="3" template="btn-accept-job" />
          <dx-item :col-span="3" template="btn-start-job" />
          <dx-simple-item :col-span="3" template="btn-stop-job" />
          <dx-simple-item :col-span="3" template="btn-reject-job" />

          <template #btn-accept-job>
            <DxButton
              :width="btnAcceptJobOptions.width"
              :type="btnAcceptJobOptions.type"
              :icon="btnAcceptJobOptions.icon"
              :text="$t('resource_selection.btn_accept_job')"
              :disabled="!isValid"
              @click="btnAcceptJobOptions.onClick"
            />
          </template>

          <template #btn-start-job>
            <DxButton
              :width="btnStartJobOptions.width"
              :type="btnStartJobOptions.type"
              :icon="btnStartJobOptions.icon"
              :text="$t('resource_selection.btn_start_job')"
              :disabled="!isValid"
              @click="btnStartJobOptions.onClick"
            />
          </template>

          <template #btn-stop-job>
            <DxButton
              :width="btnStopJobOptions.width"
              :type="btnStopJobOptions.type"
              :icon="btnStopJobOptions.icon"
              :text="$t('resource_selection.btn_finish_job')"
              :disabled="!isValid"
              @click="btnStopJobOptions.onClick"
            />
          </template>

          <template #btn-reject-job>
            <DxButton
              :width="btnRejectJobOptions.width"
              :type="btnRejectJobOptions.type"
              :icon="btnRejectJobOptions.icon"
              :text="$t('resource_selection.btn_reject_job')"
              :disabled="!isValid"
              @click="btnRejectJobOptions.onClick"
            />
          </template>
        </dx-form>
      </div>
      <DxLoadPanel
        :visible="loading"
        :position="{ of: '#content-container' }"
        shading-color="rgba(0,0,0,0.4)"
        :close-on-outside-click="false"
      />
      <PscopeDisclosures
        :permissionTypes="['background-locations']"
        :show="showDisclosure"
        :manualTrigger="true"
        @agreed="onDisclosureAgreed"
        @disagreed="onDisclosureDisagreed"
      />
    </VuePullRefresh>
  </div>
</template>

<script>
import util from "../../commons/utility";
import service from "../../commons/service";
import i18n from "../../i18n";
import VuePullRefresh from "vue-pull-refresh";
import DxLoadPanel from "devextreme-vue/load-panel";
import { DxForm, DxItem, DxSimpleItem } from "devextreme-vue/form";
import { DxButton } from "devextreme-vue/button";
import { DxLookup, DxDropDownOptions } from "devextreme-vue/lookup";
import notify from "devextreme/ui/notify";
import auth from "../../auth";
import api from "../../commons/api";
import DataSource from "devextreme/data/data_source";
import ArrayStore from "devextreme/data/array_store";
import JobItem from "./job-item";
import SelectedDataAccordion from "../../components/selected-data-accordion";
import PscopeDisclosures from "../../components/disclosure/pscope-disclosures";

const ACCEPT_JOB = "Accept Job";
const START_JOB = "Start Job";
const FINISH_JOB = "Finish Job";
const REJECT_JOB = "Reject Job";

export default {
  computed: {
    isValid: function () {
      return (
        this.selectedResourceId != null && this.selectedAllocationId != null
      );
    },
    allocationPlaceHolder: function () {
      if (this.selectedAllocationId) return this.selectedAllocationId;

      return this.$t("resource_selection.title");
    },
  },
  mounted() {
    this.loadAllocations();
  },
  data() {
    const _prevSelectedResourceID = util.getSelectedResourceId();
    const _prevSelectedAllocationID = util.getSelectedAllocationId();

    return {
      loading: false,
      allocationDataSource: undefined,
      showDisclosure: false,
      pendingDisclosureAction: undefined,

      selectedResourceId: _prevSelectedResourceID,
      selectedAllocationId: _prevSelectedAllocationID,

      currentLat: undefined,
      currentLon: undefined,

      btnAcceptJobOptions: {
        icon: "folder",
        type: "success",
        width: "100%",
        onClick: this.clickAcceptJob,
      },

      btnStartJobOptions: {
        icon: "video",
        type: "success",
        width: "100%",
        onClick: this.clickStartJob,
      },

      btnStopJobOptions: {
        icon: "warning",
        type: "warning",
        width: "100%",
        onClick: this.clickStopJob,
      },

      btnRejectJobOptions: {
        icon: "clear",
        type: "danger",
        width: "100%",
        onClick: this.clickRejectJob,
      },

      colCountByScreen: {
        xs: 1,
        sm: 3,
        md: 6,
        lg: 12,
      },
    };
  },

  methods: {
    onDisclosureAgreed() {
      this.showDisclosure = false;
      if (this.pendingDisclosureAction) {
        this.$nextTick(() => {
          this.pendingDisclosureAction();
          this.pendingDisclosureAction = undefined;
        });
      }
    },
    onDisclosureDisagreed() {
      this.showDisclosure = false;
      this.pendingDisclosureAction = undefined;
    },
    onCurrentLocationChanged(lat, lon) {
      this.currentLat = lat;
      this.currentLon = lon;
    },
    async handleDataLoaded(response) {
      {
        const allocationId = this.selectedAllocationId;

        util.saveSelectedResourceAndAllocation(
          this.selectedResourceId,
          allocationId
        );
        this.loadAndSelectAllocationPlans(allocationId);

        await service.downloadResourcesForOfflineMode(allocationId);

        this.loading = false;
        notify(response.data.ErrorMessage, "success", 2000);
      }
    },
    onRefresh() {
      return new Promise(function (resolve, reject) {
        setTimeout(function () {
          resolve();
          this.refresh();
        }, 500);
      });
    },
    refresh() {
      this.loadAllocations();
    },
    loadAllocations() {
      const userName = auth.getUserId();
      service.getAllocationsByUserName(userName).then((data) => {
        this.allocationDataSource = new DataSource({
          key: "AllocationID",
          paginate: false,
          searchExpr: ["ResourceID", "AllocationID"],
          store: new ArrayStore({
            key: "AllocationID",
            data: [...data],
          }),
        });
      });
    },
    onAllocationChanged(e) {
      const mAllocationId = e.value;

      this.allocationDataSource
        .store()
        .byKey(mAllocationId)
        .then((data) => {
          this.selectedAllocationId = data.AllocationID;
          this.selectedResourceId = data.ResourceID;
        });
    },
    loadAndSelectAllocationPlans(allocationId) {
      // this.$http
      //   .get(`${api.API_URL}${api.GET_ALLOCATION_PLAN_URL}`, {
      //     params: {
      //       allocationID: allocationId,
      //     },
      //   })
      //   .then((resp) => {
      //     if (resp != null && resp.data != null && resp.data.length > 0) {
      //       util.saveSelectedSite(resp.data[0]);
      //     }
      //   });
    },
    async clickAcceptJob() {
      const result = await this.$root.showConfirmationDialog(
        i18n.t("common.lbl_confirmation"),
        i18n.t("resource_selection.msg_confirm_accept_job")
      );
      if (!result) return;

      this.loading = true;

      let param = {
        ResourceID: this.selectedResourceId,
        AllocationID: this.selectedAllocationId,
        LogType: ACCEPT_JOB,
      };

      this.$http
        .post(api.POST_ALLOCATION_ACTION_URL, param)
        .then(this.handleDataLoaded)
        .catch((err) => {
          this.loading = false;
          notify("error", "error", 2000);
        });
    },
    clickStartJob() {
      if (util.shouldShowDisclosure()) {
        this.pendingDisclosureAction = this.startJob;
        this.showDisclosure = true;
      } else {
        this.startJob();
      }
    },
    async startJob() {
      const result = await this.$root.showConfirmationDialog(
        i18n.t("common.lbl_confirmation"),
        i18n.t("resource_selection.msg_confirm_start_job")
      );
      if (!result) return;

      this.loading = true;

      let param = {
        ResourceID: this.selectedResourceId,
        AllocationID: this.selectedAllocationId,
        LogType: START_JOB,
        Latitude: this.currentLat,
        Longitude: this.currentLon,
      };

      this.$http
        .post(api.POST_ALLOCATION_ACTION_URL, param)
        .then((res) => {
          this.loading = false;

          notify(res.data.ErrorMessage, "success", 2000);

          util.saveSelectedResourceAndAllocation(
            this.selectedResourceId,
            this.selectedAllocationId
          );
          this.loadAndSelectAllocationPlans(this.selectedAllocationId);

          // start tracker
          if (this.$root.deviceReady) {
            this.$startPscopeTracker();
          } else {
            setTimeout(() => {
              notify(
                "Cannot start tracker, your device is not ready or not supported. Please try again...",
                "error"
              );
            }, 2000);
          }
        })
        .catch((err) => {
          this.loading = false;
          notify("error", "error", 2000);
        });
    },
    clickStopJob2() {
      if (util.shouldShowDisclosure()) {
        this.pendingDisclosureAction = this.stopJob;
        this.showDisclosure = true;
      } else {
        this.stopJob();
      }
    },
    async clickStopJob() {
      const result = await this.$root.showConfirmationDialog(
        i18n.t("common.lbl_confirmation"),
        i18n.t("resource_selection.msg_confirm_finish_job")
      );
      if (!result) return;

      this.loading = true;

      let param = {
        ResourceID: this.selectedResourceId,
        AllocationID: this.selectedAllocationId,
        LogType: FINISH_JOB,
        Latitude: this.currentLat,
        Longitude: this.currentLon,
      };

      this.$http
        .post(api.POST_ALLOCATION_ACTION_URL, param)
        .then((res) => {
          this.loading = false;
          this.resetState();
          this.refresh();

          notify(res.data.ErrorMessage, "success", 2000);
          util.clearSelectedResourceAndAllocation();
          util.clearSelectedSite();

          // sync all remaining data
          const thisComp = this;
          this.$syncTracker(
            (records) => {
              thisComp.$stopPscopeTracker();
            },
            (e) => {
              thisComp.$stopPscopeTracker();
            }
          );
        })
        .catch((err) => {
          this.loading = false;
          this.refresh();
          notify("error", "error", 2000);

          const thisComp = this;
          this.$syncTracker(
            (records) => {
              thisComp.$stopPscopeTracker();
            },
            (e) => {
              thisComp.$stopPscopeTracker();
            }
          );
        });
    },
    async clickRejectJob() {
      const result = await this.$root.showConfirmationDialog(
        i18n.t("common.lbl_confirmation"),
        i18n.t("resource_selection.msg_confirm_reject_job")
      );
      if (!result) return;

      this.loading = true;

      let param = {
        ResourceID: this.selectedResourceId,
        AllocationID: this.selectedAllocationId,
        LogType: REJECT_JOB,
      };

      this.$http
        .post(api.POST_ALLOCATION_ACTION_URL, param)
        .then((res) => {
          this.loading = false;
          this.resetState();
          this.refresh();

          notify(res.data.ErrorMessage, "success", 2000);
        })
        .catch((err) => {
          this.loading = false;
          this.refresh();
          notify("error", "error", 2000);
        });
    },

    resetState() {
      this.selectedResourceId = null;
      this.selectedAllocationId = null;
    },
  },

  components: {
    VuePullRefresh,
    DxLoadPanel,
    DxForm,
    DxItem,
    DxButton,
    DxSimpleItem,
    DxLookup,
    DxDropDownOptions,
    JobItem,
    SelectedDataAccordion,
    PscopeDisclosures,
  },
};
</script>

<style lang="scss"></style>
